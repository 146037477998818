<template>
  <div>
    <div v-if="loading" class="empty-image center-content">
      <span><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Cargando...</span>
    </div>
    <span v-else>
      <recibos-table :provId="provId" :es_proveedor="es_proveedor" :todas="todas" :refresh="updateData" @validated="validated($event)" />
    </span>
  </div>
</template>

<script>
import RecibosTable from '@/components/RecibosTable.vue';

export default {
  name: 'RecibosContainer',
  components: {
    RecibosTable,
  },
  props: [
    'provId',
    'refresh',
    'es_proveedor',
    'todas',
  ],
  data() {
    return {
      loading: false,
      updateData: false,
    };
  },
  methods: {
    validated(modalMsg = '') {
      this.$emit('validated', modalMsg);
    },
  },
  watch: {
    refresh() {
      this.updateData = !this.updateData;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
