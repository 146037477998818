<template>
    <div class="btn-action-md">
      <b-button class="btn-detail mr-1"
        v-if="$descargar(['Facturas'])"
        @click="$descargar(['Facturas']) && params.data.CargaId ? generarRecibo() : undefined"
        :disabled="params.loading"
        v-b-popover.hover="'Generar Recibo'">
        <font-awesome-icon v-if="params.loadingViewId == params.data.CargaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'file-pdf' }" class="icon btn-icon"/>
        Generar Recibos</b-button>
      <!-- <b-button class="btn-detail" v-if="$eliminar(['Facturas']) && !params.es_proveedor && !params.todas"
        @click="params.data.CargaId ? showDeleteFile() : undefined"
        :disabled="params.loading"
        v-b-popover.hover="'Eliminar recibo'">
        <font-awesome-icon v-if="params.loadingDelId == params.data.CargaId" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/>
        <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'trash-alt', }" class="icon btn-icon"/>
      </b-button> -->
    </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: [
    'loading',
    'loadingViewId',
    'loadingDelId',
    'es_proveedor',
    'todas',
  ],
  data() {
    return {
    };
  },
  methods: {
    generarRecibo() {
      this.params.context.componentParent.generarRecibo(
        this.params.data.CargaId,
      );
    },
    showDeleteFile() {
      this.params.context.componentParent.showDeleteFile(this.params.data.CargaId);
    },
  },
});
</script>

<style lang="scss" scoped>
.btn-icon {
  padding: 0 .30rem;
  width: 1.2rem !important;
  height: auto;
}
.btn-detail {
  &.btn-icon-text1 {
    width: 55px;
  }
}
</style>
