import { render, staticRenderFns } from "./RecibosContainer.vue?vue&type=template&id=1deea930&scoped=true&"
import script from "./RecibosContainer.vue?vue&type=script&lang=js&"
export * from "./RecibosContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1deea930",
  null
  
)

export default component.exports