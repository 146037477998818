<template>
  <div>
    <fieldset class="fieldset-dropzone">
      <legend>Subir Archivo XML y PDF</legend>
      <VueFileAgent
        ref="vueFileAgent"
        class="dropzone file-agent-scroll"
        :theme="'list'"
        :multiple="true"
        :deletable="true"
        :disabled="loading"
        :meta="true"
        :accept="'text/xml,.xml/,application/pdf,.pdf'"
        :maxSize="`15MB`"
        :maxFiles="maxFiles * 2"
        :helpText="`ARRASTRE AQUI O SELECCIONE ARCHIVOS PDF Y XML (AMBOS)`"
        :errorText="{
          type: `Archivo no permitido, solo se admiten extensiones: pdf y xml`,
          size: `El archivo no debe exceder 15MB`,
        }"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        v-model="files"
      ></VueFileAgent>

      <div class="row">
        <div class="col-12">
          <label class="text-note"> {{ `Tamaño máximo permitido por archivo&nbsp;5 MB, Extensiones&nbsp;.PDF y .XML. Máximo de archivos por carga: ${maxFiles}` }}</label><br>
        </div>
        <div class="col-12 mb-2">
          <b-progress v-if="progress > 0" :max="100" show-progress animated>
            <b-progress-bar :value="progress" :label-html="`<strong>Validando ${progress}%</strong>`"></b-progress-bar>
          </b-progress>
        </div>
        <div class="col-12 mb-2" >
          <b-alert variant="danger" v-if="filesError.length > 0" show>
            <template v-for="error in filesError">
              <div :key="error.nombre">⚠️El archivo <strong>{{ error.nombre }}</strong> no cuenta con {{ !error.pdf ? 'PDF' : 'XML' }}.</div>
            </template>
          </b-alert>
        </div>
        <div class="col-12 text-right">
          <b-button class="btn-detail" @click="uploadFiles" :disabled="(loading || !this.validFiles) ? true : false">
            <span v-if="loading"><font-awesome-icon :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin/> Enviando {{ this.filesToUpload.length }} archivo(s)</span>
            <span v-if="!loading"><font-awesome-icon :icon="{ prefix: 'fa', iconName: 'paper-plane', }" class="icon btn-icon"/> Enviar {{ this.filesToUpload.length }} archivo(s)</span></b-button>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';

export default {
  name: 'UploadBox',
  props: [
    'provId',
  ],
  data() {
    return {
      loading: false,
      maxFiles: 30,
      files: [],
      filesToUpload: [],
      filesValidToUpload: [],
      filesError: [],
      progress: 0,
      validFiles: false,
      errores: [],
    };
  },
  methods: {
    filesSelected(files) {
      // this.filesError = [];

      if (files.length) {
        const validFileRecords = files.filter((fileRecord) => !fileRecord.error);
        if (validFileRecords.length) this.filesToUpload.push(...validFileRecords);
      }
    },
    onBeforeDelete(file) {
      this.files = this.files.filter((fileRecord) => fileRecord.name() !== file.name());
      this.filesToUpload = this.filesToUpload.filter((fileRecord) => fileRecord.name() !== file.name());
    },
    uploadFiles() {
      if (this.filesValidToUpload.length === 0) return;
      let count = 0;
      apiClient.post('/facturas/carga', {
        ProvId: this.provId,
      }).then((resultCarga) => {
        Promise.all(this.filesValidToUpload.map((file) => {
          this.loading = true;
          // Formulario para envio de archivo con data
          const formData = new FormData();
          const endpoint = '/facturas';
          formData.append('File', file.filePdf);
          formData.append('File', file.fileXml);
          formData.append('ProvId', this.provId);
          return apiClient.post(endpoint, formData, {
            headers: {
              'Content-Type': 'multipart/form-data;',
              CId: resultCarga.data.Cid,
            },
          }).finally(() => {
            count += 1;
            this.progress = Math.round((count * 100) / this.filesToUpload.length);
          });
        })).then((res) => {
          let errores = '';
          res.forEach((response) => { if (response.status === 202) errores = (errores ? `${errores}<br>❌${response.data.ms}` : `❌${response.data.ms}`); });
          this.loading = false;
          this.successfulLoaded(errores);
        }).catch((err) => {
          this.loading = false;
          this.progress = 0;
          this.$bvModal.msgBoxOk(err.response.data.ms || '', {
            title: err.response.data.title || 'Atención',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'OK',
            cancelTitle: 'Cancelar',
            headerClass: 'text-light',
            bodyClass: 'max-height-70',
            footerClass: 'bg-light p-2',
            hideHeaderClose: true,
            centered: true,
          });
        // this.successfulLoaded();
        });
      }).catch(() => {
        this.loading = false;
        this.progress = 0;
      });
    },
    successfulLoaded(modalMsg = '') {
      this.$emit('submit', modalMsg);
    },
  },
  watch: {
    filesToUpload(files) {
      this.filesError = [];
      this.filesValidToUpload = [];
      const filesEstatus = {};
      files.forEach((file) => {
        const name = this.$removeExtension(file.name());
        if (!filesEstatus[name]) filesEstatus[name] = {};
        if (file.type.includes('text/xml')) {
          filesEstatus[name].xml = true;
          filesEstatus[name].fileXml = file.file;
        }
        if (file.type.includes('application/pdf')) {
          filesEstatus[name].pdf = true;
          filesEstatus[name].filePdf = file.file;
        }
      });

      if (files.length > 0) {
        this.validFiles = true;
        Object.entries(filesEstatus).forEach((file) => {
          if (!(file[1].xml === true && file[1].pdf === true)) {
            this.validFiles = false;
            this.filesError.push({ nombre: file[0], pdf: file[1].pdf, xml: file[1].xml });
          } else {
            this.filesValidToUpload.push({ filePdf: file[1].filePdf, fileXml: file[1].fileXml });
          }
        });
      } else this.validFiles = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.file-agent-scroll {
  max-height: 20vh;
  overflow: auto;
}
</style>
