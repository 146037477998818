<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
      </div>
      <div class="col-lg-6 text-right">
        <b-button class="btn-detail m-2"
          @click="!loading ? updateTable() : undefined"
          :disabled="loading"
          v-b-popover.hover="'Actualizar listado'">
          <font-awesome-icon v-if="loading" :icon="{ prefix: 'fas', iconName: 'spinner', }" class="icon btn-icon" spin />
          <font-awesome-icon v-else :icon="{ prefix: 'fa', iconName: 'sync-alt', }" class="icon btn-icon"/>
        </b-button>
      </div>
    </div>
    <!-- Tabla de datos -->
    <transition name="slide-fade" appear>
      <ag-grid-vue
          class="ag-grid-table ag-theme-balham"
          style='height: 60vh'
          :localeText="locale"
          :animateRows="true"
          rowSelection="multiple"
          :loadingCellRenderer="'loadingCellRenderer'"
          :getRowId="getRowId"

          :gridOptions="gridOptions"
          :context="context"
          :suppressDragLeaveHidesColumns="true"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :overlayNoRowsTemplate="$overlayNoRowsTemplate()"
          :frameworkComponents="frameworkComponents"

          :rowModelType="rowModelType"
          :cacheBlockSize="paginationPageSize"

          @grid-ready="onGridReady"
          @gridSizeChanged="gridSizeChanged"
          @row-selected="rowSelected"
          @rowDoubleClicked="rowDblClick">
      </ag-grid-vue>
    </transition>

    <receipt class="hidden" ref="recibo" :fecha-carga="fechaCarga" :proveedor="nomProveedor" :partidas-empresas="partidasRecibo"/>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import apiClient from '@/services/axios';
import AgCellActions from '@/components/RecibosActions.vue';
import AgCellPopoverVue from '@/components/AgCellPopover.vue';
import Receipt from '@/components/Recibo.vue';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

export default {
  name: 'RecibosTable',
  components: {
    AgGridVue,
    Receipt,
  },
  props: [
    'provId',
    'es_proveedor',
    'todas',
    'refresh',
    'archivos',
  ],
  data() {
    return {
      loadingViewId: 0,
      loadingDelId: 0,
      loading: false,
      /** Datos para generar recibo */
      fechaCarga: '',
      nomProveedor: '',
      partidasRecibo: [],
      /** Configuracion de tabla ag-grid */
      // eslint-disable-next-line no-undef
      locale: AG_GRID_LOCALE_ES,
      context: null,
      gridOptions: {
        suppressMenuHide: true,
      },
      defaultColDef: {
        floatingFilter: true,
        resizable: true,
        sortable: true,
        filter: true,
        unSortIcon: true,
      },
      frameworkComponents: {
        actionsTemplate: AgCellActions,
        popoverTemplate: AgCellPopoverVue,
      },
      selectedRows: [],
      selectedRowKeys: [],
      getRowId: (params) => params.data.FacturaId,
      columnDefs: [
        // {
        //   headerName: '#',
        //   field: 'CargaId',
        //   filter: false,
        //   checkboxSelection: true,
        //   width: '80px',
        //   cellClass: 'text-center line-numbers',
        // },
        {
          headerName: 'Fecha Carga',
          field: 'Fecha',
          cellClass: 'text-center',
          cellRendererSelector: (params) => ({
            component: 'popoverTemplate',
            params: {
              popoverText: `<strong>Fecha:</strong> ${params.data.Fecha}<br><strong>Autor:</strong> ${params.data.Nombre}`,
            },
          }),
          filter: 'agDateColumnFilter',
        },
        {
          headerName: 'Proveedor',
          field: 'RazonSocial',
          width: '320px',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'RFC',
          field: 'Rfc',
          cellClass: 'text-center',
          filter: 'agTextColumnFilter',
        },
        {
          headerName: 'Cfdis Cargados',
          field: 'Cfdis_total',
          // width: '100px',
          // valueFormatter: (params) => this.$currencyFormat(params?.value),
          cellClass: 'text-center',
          filter: false,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
        },
        {
          headerName: 'Acciones',
          filter: false,
          floatingFilter: false,
          suppressMenu: true,
          sortable: false,
          lockPosition: 'right',
          suppressMovable: true,
          width: '215px',
          cellClass: 'text-center',
          cellRendererSelector: () => ({
            component: 'actionsTemplate',
            params: {
              loading: this.loading,
              loadingViewId: this.loadingViewId,
              loadingDelId: this.loadingDelId,
              es_proveedor: this.es_proveedor,
              todas: this.todas,
            },
          }),
        },
      ],
      rowModelType: 'serverSide',
      paginationPageSize: 30,
      firstRender: true,
      filters: '',
    };
  },
  mounted() {
    this.context = { componentParent: this };
  },
  methods: {
    // Evento cuando la tabla se inicializa
    onGridReady(params) {
      // Creamos variable global de params (propiedates y funciones de agGrid)
      this.agGrid = params;
      // Llamada a la funcion que actualiza la tabla
      this.getData();
      this.gridSizeChanged(this.agGrid);
      // this.idEmpresa = { EmpresaId: 0, Nombre: '(TODAS)', Alias: '(TODAS)' };
    },
    // Metodo para ajustar tamaño de tabla cuando las columnas no cubren por completo el grid
    gridSizeChanged(params) {
      const allColumnIds = [];
      params.columnApi.getAllColumns().forEach((column) => {
        if (!column.colDef.autoSize) { allColumnIds.push(column.colId); }
      });

      // params.columnApi.autoSizeColumns(allColumnIds);
    },
    // Metodo para actualizar filas seleccionadas
    rowSelected(params) {
      this.selectedRows = params.api.getSelectedNodes();
      this.selectedRowKeys = this.selectedRows.map((node) => node.data.FacturaId);
      // Actualizar datos de encabezado del recibo
      this.fechaCarga = params.data.Fecha;
      this.nomProveedor = params.data.RazonSocial;
      this.datosProveedor = params.data.RazonSocial;
    },
    // Metodo para abrir modal al dar doble click en una fila
    rowDblClick(/* params */) {
      if (this.$permisos_or([['Facturas', 'Ver'], ['Facturas', 'Editar']])) {
        // this.showModalEdit(params.data);
      }
    },
    // Metodo para limpiar todos los filtros
    clearFilters() {
      if (Object.entries(this.filters.filterModel).length) this.loading = true;
      this.agGrid.api.setFilterModel(null);
    },
    // Metodo para actualiza datos de la tabla incluyendo filtros
    updateTable() {
      this.getData();
      // this.loading = true
      // this.agGrid.api.refreshInfiniteCache()
    },
    // Metodo para actualizar datos de la tabla (server - side)
    getData() {
      const endpoint = this.todas ? 'recibos/all' : 'recibos';
      this.agGrid.api.setServerSideDatasource(
        this.$createDataSource(
          this,
          apiClient,
          endpoint, // ruta
          {
            ProvId: this.provId,
          },
        ),
      );
    },
    generarRecibo(cargaId) {
      apiClient.get('/recibos/partidas', {
        params: {
          ProvId: this.provId,
          cargaId,
        },
      })
        .then((res) => {
          this.partidasRecibo = res.data;
          this.$nextTick(() => {
            this.generarPdf();
          });
        })
        .catch(() => {});
    },
    generarPdf() {
      const element = this.$refs.recibo.$el;
      const clonedElement = element.cloneNode(true);
      clonedElement.style.display = 'block';

      const date = this.$formatDateToString(new Date());
      const opt = {
        margin: 0.25,
        filename: `Contrarecibo_${date}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        pagebreak: { mode: 'avoid-all', after: '#recibo' },
      };

      html2pdf().set(opt).from(clonedElement).save();
    },
  },
  watch: {
    refresh() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
